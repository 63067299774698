export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
state : {
    searchlocations : null,
},

getters : {
	searchlocations: (state )=> state.searchlocations,
},


mutations : {
	setsearchlocations: (state, searchlocations) => (state.searchlocations = searchlocations),
},

actions : {
    async searchgloballocations( { commit }, data) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'searchlocations')
		await axios.post("essentials/locations/globalsearch",  data)
		.then(response => {	
			if(response.data.res){
				commit('setsearchlocations', response.data.res)
			}
			commit('setpageloader', false)
			commit('setloader', false)
		})
		.catch(error=> {
			commit('setpageloader', false)
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
			commit('setloader', false)
		});
				
	},
},
}