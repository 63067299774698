export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
 state : {
    professions : null,
	profession : null,
},

getters : {
	profession: (state )=> state.profession,
	professions: (state )=> state.professions,
},


actions : {


	async getserachedprofessions( { commit }, data) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'getprofessionlist')
		await axios.post("essentials/professions/search",  data)
		.then(response => {	
			if(response.data.res){
				commit('setprofessions', response.data.res)
			}
			commit('setpageloader', false)
			commit('setloader', false)
		})
		.catch(error=> {
			commit('setpageloader', false)
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
			commit('setloader', false)
		});
				
	},

    async saveprofession( { commit }, data) {
		commit('seterror', '')
		commit('setloader', 'saveprofession')
        return new Promise((resolve, reject) => {
		 axios.post("essentials/professions/create",  data)
		.then(response => {	
			
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setprofessions', response.data.res)
			}
			commit('setloader', false)
			resolve(response)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},

    async getprofession( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.post("essentials/professions/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setprofession', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},


	async changeprofessionstatus( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.post("essentials/professions/status/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setprofessions', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},



	async getprofessionlist( { commit }, {data, page}) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'getprofessionlist')
		return new Promise((resolve, reject) => {
		axios.post("essentials/professions?page=" + page, data)
		.then(response => {	

			commit('setloader', false)
			if(response.data){
				commit('setmsg', response.data.msg)
				commit('setprofessions', response.data.res)
			}
			resolve(response)
			
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
						toast.error(error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterrors', error.response.data.message)
					}
					
				}
			}
			reject(error)
		});
		});	       
	},




    async updateprofession({commit}, {data, id}){
        const pid = ('slug', id);
        const pdata =('data', data)
        commit('setloader', pid)
        return new Promise((resolve, reject) => {
        axios.post("essentials/professions/update/" + pid, pdata)
        .then(response => {
            if(response.data.res){
                commit('setmsg', response.data.msg)
                commit('setprofession', '')
				commit('setprofessions', response.data.res)
			}
            resolve()
			commit('setloader', false)
            resolve()
        })
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
			
		});  
        });
    },




	async deleteprofession( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.post("essentials/professions/delete/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setprofessions', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},



},

mutations : {
	setprofession: (state, profession) => (state.profession = profession),
	setprofessions :(state,professions ) => (state.professions = professions), 

},
}