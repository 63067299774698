export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
 state : {
    villages : null,
	village : null,
},

getters : {
	village: (state )=> state.village,
	villages: (state )=> state.villages,
},


actions : {


	async getserachedvillages( { commit }, data) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'getserachedvillages')
		await axios.post("essentials/locations/villages/search",  data)
		.then(response => {	
			if(response.data.res){
				commit('setvillages', response.data.res)
			}
			commit('setpageloader', false)
			commit('setloader', false)
		})
		.catch(error=> {
			commit('setpageloader', false)
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
			commit('setloader', false)
		});
				
	},


    async savevillage( { commit }, data) {
		commit('seterror', '')
		commit('setloader', 'savevillage')
        return new Promise((resolve, reject) => {
		 axios.post("essentials/locations/villages/create",  data)
		.then(response => {	
			
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setvillages', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},

    async getvillage( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.get("essentials/locations/villages/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setvillage', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},


	async deletevillage( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.get("essentials/locations/villages/delete/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setvillages', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},


    async changevillagestatus( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.get("essentials/locations/villages/status/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setvillages', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},


    async getparishvillages( { commit }, {id, page}) {
            commit('setloader', id)
            commit('seterror', '')
            return new Promise((resolve, reject) => {
             axios.get("essentials/locations/parishes/getvillages/" + id + "?page="+ page)
            .then(response => {	
                if(response.data.res){
                    commit('setmsg', response.data.msg)
                    commit('setvillages', response.data.res)
                }
                resolve()
                commit('setloader', false)
            })
            .catch(error=> {
                if (error.response) {
                    commit('seterror', error.response.data.message)
                    commit('setloader', false)
                    if(error.response.data){
                        commit('seterror', error.response.data.message)
                    }
                }
                reject()
                commit('setloader', false)
            });
         });
                    
    },

    async getvillagelist( { commit }, data) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'getvillagelist')
		await axios.get("essentials/locations/villages",  data)
		.then(response => {	
			if(response.data.res){
				commit('setvillages', response.data.res)
			}
			commit('setpageloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setpageloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
		});
				
	},


    async updatevillage({commit}, {data, id}){
        const pid = ('slug', id);
        const pdata =('data', data)
        commit('setloader', pid)
        return new Promise((resolve, reject) => {
        axios.post("essentials/locations/villages/update/" + pid, pdata)
        .then(response => {
            if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setvillage', '')
                commit('setvillages', response.data.res)
			}
            resolve()
			commit('setloader', false)
            resolve()
        })
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
			
		});  
        });
    },



},

mutations : {
	setvillage: (state, village) => (state.village = village),
	setvillages :(state,villages ) => (state.villages = villages), 

},
}