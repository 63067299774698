//import router from '@/router'
import store from '@/store'
  const adminguard = (to, from, next) => {
    if(localStorage.getItem('eocadessentialsaccessauthtoken') != null){
      if(store.getters.authuser != null ){
        if(store.getters.authuser.role == 'essentials'){
          return next()
        }else{
          return next({ name: 'login' })
        }
        }
      else{
        // router.push({ name: "welcome"})
        return next({ name: 'login' })
      }
    }
    else{
      return next({ name: 'login' })
    }
  };



  let routes = [
    { 
        path: "/",
        props: true,
      
        component: () => import("@/views/app/dash.vue"),
        title: 'Manage Essential of EOCAD ',
          children: [

            {
            path: "/",
              name: "welcome",
              beforeEnter: adminguard, 
              props:true,
              component: () => import("@/views/app/home/welcome.vue"),
              meta: {
              title: ' Welcome to your EOCAD Essentials Dashboard'
              }
            },

            {
            path: "/searchlocations",
              name: "searchlocations",
              beforeEnter: adminguard, 
              props:true,
              component: () => import("@/views/app/home/searchlocations.vue"),
              meta: {
              title: 'Your Search Results on this Page'
              }
            },


            {
              path: "/professions",
                name: "professions",
                beforeEnter: adminguard, 
                props:true,
                component: () => import("@/views/app/proffs/professions.vue"),
                meta: {
                title: 'Manage Professions'
                }
              },


              {
                path: "/industries",
                  name: "industries",
                  beforeEnter: adminguard, 
                  props:true,
                  component: () => import("@/views/app/proffs/industries.vue"),
                  meta: {
                  title: 'Manage Professions'
                  }
                },
  






            //locations
            {
              path: "/locations/countries",
              name: "locations",
              beforeEnter: adminguard, 
              props:true,
              component: () => import("@/views/app/locations/locations.vue"),
              meta: {
              title: 'Manage Locations From Here'
              }
            },

            {
              path: "/locations/countries/:id",
              name: "countrydetails",
              beforeEnter: adminguard, 
              props:true,
              component: () => import("@/views/app/locations/countrydistricts.vue"),
              meta: {
              title: 'Manage Country Districts From Here'
              }
            },
            {
              path: "/locations/countries/district/:id",
              name: "districtcounties",
              beforeEnter: adminguard, 
              props:true,
              component: () => import("@/views/app/locations/districtcounties.vue"),
              meta: {
              title: 'Manage  District Counties From Here'
              }
            },

            {
              path: "/locations/countries/district/county/:id",
              name: "countydivisions",
              beforeEnter: adminguard, 
              props:true,
              component: () => import("@/views/app/locations/countydivisions.vue"),
              meta: {
              title: 'Manage  District Counties From Here'
              }
            },

            {
              path: "/locations/countries/district/county/division/:id",
              name: "divisionparishes",
              beforeEnter: adminguard, 
              props:true,
              component: () => import("@/views/app/locations/divisionparishes.vue"),
              meta: {
              title: 'Manage  District Counties From Here'
              }
            },

            {
              path: "/locations/countries/district/county/division/parish/:id",
              name: "parishvillages",
              beforeEnter: adminguard, 
              props:true,
              component: () => import("@/views/app/locations/parishvillages.vue"),
              meta: {
              title: 'Manage  District Counties From Here'
              }
            },


          ]
        }
    ]


    export default routes;