export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
 state : {
    industries : null,
	industry : null,
},

getters : {
	industry: (state )=> state.industry,
	industries: (state )=> state.industries,
},


actions : {


	async getserachedindustries( { commit }, data) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'getindustrylist')
		await axios.post("essentials/industries/search",  data)
		.then(response => {	
			if(response.data.res){
				commit('setindustries', response.data.res)
			}
			commit('setpageloader', false)
			commit('setloader', false)
		})
		.catch(error=> {
			commit('setpageloader', false)
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
			commit('setloader', false)
		});
				
	},

    async saveindustry( { commit }, data) {
		commit('seterror', '')
		commit('setloader', 'saveindustry')
        return new Promise((resolve, reject) => {
		 axios.post("essentials/industries/create",  data)
		.then(response => {	
			
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setindustries', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},

    async getindustry( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.post("essentials/industries/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setindustry', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},


	async changeindustriestatus( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.post("essentials/industries/status/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setindustries', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},


	async getindustrylist( { commit }, {data, page}) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'getindustrylist')
		return new Promise((resolve, reject) => {
		axios.post("essentials/industries?page=" + page, data)
		.then(response => {	

			commit('setloader', false)
			if(response.data){
				commit('setmsg', response.data.msg)
				commit('setindustries', response.data.res)
			}
			resolve(response)
			
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
						toast.error(error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterrors', error.response.data.message)
					}
					
				}
			}
			reject(error)
		});
		});	       
	},




    async updateindustry({commit}, {data, id}){
        const pid = ('slug', id);
        const pdata =('data', data)
        commit('setloader', pid)
        return new Promise((resolve, reject) => {
        axios.post("essentials/industries/update/" + pid, pdata)
        .then(response => {
            if(response.data.res){
                commit('setmsg', response.data.msg)
                commit('setindustry', '')
				commit('setindustries', response.data.res)
			}
            resolve()
			commit('setloader', false)
            resolve()
        })
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
			
		});  
        });
    },




	async deleteindustry( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.post("essentials/industries/delete/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setindustries', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},



},

mutations : {
	setindustry: (state, industry) => (state.industry = industry),
	setindustries :(state,industries ) => (state.industries = industries), 

},
}